<template>

	<div>

	<Header
		title="Beispiele"
		subtitle="Von schwer bis sehr leicht"
	></Header>
	
	
  	<v-container fluid white>
  	<v-container appwidth class="py-15" black--text>

		
		<v-row>		
			<v-col cols="12" sm="12" md="3" lg="3">
				<p class="mb-0 text-h6 text-md-h5">Flesch-Index von 9</p>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8">
				<p class="text-md-body-1">Cassierers Werk zur Geschichte der Erkenntnistheorie verbindet die historische Konstruktion des Erkenntnisproblems mit dem Interesse an der systematischen Gliederung der Grunderkenntnisse.<br/>
				Quelle: Kröner, „Lexikon der philosophischen Werke“</p>
			</v-col>
		</v-row>
		<v-divider/>
		<v-row class="pt-5">		
			<v-col cols="12" sm="12" md="3" lg="3">
				<p class="mb-0 text-h6 text-md-h5">Flesch-Index von 55</p>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8">
				<p class="text-md-body-1">Sie lief nach hause, am Central Park North entlang, und betrachtete die Bäume, deren Laub sich gelb und orange verfärbte, als sie Schritte hinter sich bemerkte, die genau ihrem Rhythmus folgten.<br/>
				Quelle: William Boyd, „Ruhelos“</p>
			</v-col>
		</v-row>
		<v-divider/>
		
		<v-row class="pt-5">		
			<v-col cols="12" sm="12" md="3" lg="3">
				<p class="mb-0 text-h6 text-md-h5">Flesch-Index von 69</p>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8">
				<p class="text-md-body-1">Es war nicht einfach, aber sie kämpfte darum, mit der neuen Situation gut zurechtzukommen. Vor allem die extreme Höhe machte ihr zu schaffen. In der ersten Nacht am Berg hatte sie kein Auge zugemacht.</p>
				
			</v-col>
		</v-row>
		<v-divider/>
		
		<v-row class="pt-5">		
			<v-col cols="12" sm="12" md="3" lg="3">
				<p class="mb-0 text-h6 text-md-h5">Flesch-Index von 100</p>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8">
				<p class="text-md-body-1">Die Blumen sind gelb. Der Himmel ist blau. Der Tag ist schön.</p>
			</v-col>
			
		</v-row>
				
	</v-container>
  	</v-container>
	
	
	</div>

		
</template>

<script>

import Header from '@/components/Header';

export default {
  
	name: 'Beispiele',
  
	components: {
		Header,
	},
	
	head: {
		title: {
			inner: 'Beispiele',
			separator: '-',
			complement: 'Fleschindex'
		},
  
		link: [
			{
				rel: 'canonical',
				href: 'https://fleschindex.de/beispiele/',
			}
		],
		meta: [
			{
				name: 'description',
				content: 'Beispiele für die Flesch-Index Berechnung.',
			},
			{
				name: 'keywords',
				content: 'Flesch-Index, Lesbarkeitsindex, Reading-Ease"',
			},
			{
				property: 'og:title',
				content: 'Beispiele',
			},
			{
				property: 'og:description',
				content: 'eispiele für die Flesch-Index Berechnung.',
			},
			{
				property: 'og:image',
				content: 'https://fleschindex.de/assets/img/fleschindex.jpg',
			},
			{
				property: 'og:url',
				content: 'https://fleschindex.de/beispiele/',
			},
		]
	 },

  
  
  
}
</script>
